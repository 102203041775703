<template>
  <app-image v-bind="logoBinding" src="/images/logo_full_color.svg" />
</template>

<script lang="ts" setup>
const logoBinding = {
  alt: "Pollen logo",
  class: "w-[125px] desktop-nav:w-[170px]",
  width: 170,
};
</script>
